.Logo {
    width: 140px;
    min-width: 140px;
    margin: 10px 30px 10px 0px;
}

.myCustomBadge {
    background-color: #2A2F4E;
    color: #fff;
  }

@media only screen and (min-width: 600px) {
    .Logo {
        width: 140px;
        min-width: 140px;
        margin: 10px 30px 10px 0px;
    }
}






.Container {
    max-width: 1610px;
    padding: 30px 0;
}

.Header-wrap {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Header-wrap .Header-right {
    width: 80%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.Search-style {
    width: 100%;
}

.Header-style .Search-style form {
    border: 2px solid #BCE3C9;
    border-radius: 4px;
    max-width: 700px;
    background-color: #fff;
}

.Search-style form {
    width: 100%;
    display: flex;
    position: relative;
}

.Header-style .Search-style form input {
    max-width: 600px;
    border-width: 0px;
    border-radius: 0 5px 5px 0;
    margin: 5px 20px 5px 15px;
    background-color: #fff;
    border: none;
}

.Search-style form input {
    width: 100%;
    background-image: url(../../../assets/svg/search.svg);
    background-repeat: no-repeat;
    background-position: center right;
    padding-left: 0;
    height: 40px;
    line-height: 50px;
    font-size: 14px;
    font-family: 'Montserrat';
}

.Search:focus {
    outline-color: transparent;
}

.Header-style .Select-container {
    max-width: unset;
    min-width: 150px;
}

.Header-style .Select-container--default .Select-selection--single .Select-selection__rendered {
    height: 50px;
    line-height: 50px;
    position: relative;
}

.Select-container--default .Select-selection--single .Select-selection__rendered {
    font-size: 14px;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    color: #000000;
}

.Header-style .Select-container--default .Select-selection--single {
    border: 0;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    width: 155px;
    max-width: unset;
    border-radius: 5px 0 0 5px;
}

.Header-style .Select-container--default .Select-selection--single .Select-selection__arrow {
    right: -30px;
}

.Header-style .Select-container--default .Select-selection--single .Select-selection__line {
    right: -50px;
}

.Select-container--default .Select-selection--single .Select-selection__line {
    height: 26px;
    position: absolute;
    margin-top: 13px;
    width: 20px;
}


.Select-selection__line b::before {
    content: "";
    display: block;
    width: 10px;
    height: 25px;
    background-image: url(../../../assets/svg/line.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.Select-container--default .Select-selection--single .Select-selection__arrow {
    height: 26px;
    position: absolute;
    top: 8px;
    width: 20px;
}


.Select-selection__arrow b::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-image: url(../../../assets/svg/arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
}



@media only screen and (min-width: 1200px) {
    .Header-action-right {
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        flex-shrink: 0;
        -webkit-box-align: center;
        align-items: center;
    }
}

.Header-action {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Header-action .Header-action-icon {
    position: relative;
    display: flex;
    padding: 0 10px;
}

.Header-action .Header-action-icon .Icon {
    font-size: 30px;
    color: #333;
    line-height: 1;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 25px;
}

.Header-action .Header-action-icon .Pro-count {
    background-color: #3BB77E;
    position: absolute;
    top: -5px;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    margin-left: 17px;
}

.Header-action .Header-action-icon .Lable{
    font-size: 16px;
    margin: 15px 0 0 2px;
    font-family: 'Montserrat';
}